import { Node } from "djedi-react";
import PropTypes from "prop-types";
import React from "react";

import appstoreImage from "#images/appstore.svg";

import Clickable from "../Clickable";

Appstore.propTypes = {
  className: PropTypes.string,
};

Appstore.defaultProps = {
  className: "",
};

export default function Appstore({ className }) {
  return (
    <Node
      uri="Appstore/url"
      edit={false}
      render={(state) => {
        const href = state.type === "success" ? state.content.trim() : "";
        return (
          <Clickable
            href={href}
            disabled={href === ""}
            title="Download on the App Store"
            className={className}
          >
            <img src={appstoreImage} alt="Download on the App Store" />
          </Clickable>
        );
      }}
    >
      https://itunes.apple.com/se/app/mtr-express/id1454388204?mt=8
    </Node>
  );
}
