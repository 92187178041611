import { Node } from "djedi-react";
import PropTypes from "prop-types";
import React from "react";

import googlePlayImage from "#images/google-play.svg";

import Clickable from "../Clickable";

GooglePlay.propTypes = {
  className: PropTypes.string,
};

GooglePlay.defaultProps = {
  className: "",
};

export default function GooglePlay({ className }) {
  return (
    <Node
      uri="GooglePlay/url"
      edit={false}
      render={(state) => {
        const href = state.type === "success" ? state.content.trim() : "";
        return (
          <Clickable
            href={href}
            disabled={href === ""}
            title="Get it on Google Play"
            className={className}
          >
            <img src={googlePlayImage} alt="Get it on Google Play" />
          </Clickable>
        );
      }}
    >
      https://play.google.com/store/apps/details?id=com.mtrexpress
    </Node>
  );
}
