import classnames from "classnames";
import { Node } from "djedi-react";
import Head from "next/head";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import AppContext from "../AppContext";
import Footer from "../Footer";
import Nav from "../Nav";
import styles from "./Layout.module.css";

const TITLE_BASE = "VR";

Layout.propTypes = {
  background: PropTypes.oneOf(["default", "white", "grey"]),
  // The title can be either a string or a djedi `<Node>`.
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]),
  // Descriptions must be djedi `<Node>`.
  description: PropTypes.element,
  canonical: PropTypes.string,
  noindex: PropTypes.bool,
  mainProps: PropTypes.shape({ className: PropTypes.string }),
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
  widgets: PropTypes.node,
};

Layout.defaultProps = {
  background: "default",
  mainProps: { className: undefined },
  title: "",
  description: undefined,
  canonical: undefined,
  noindex: false,
  padding: "none",
  widgets: undefined,
};

export default function Layout({
  background,
  title,
  description,
  canonical,
  noindex,
  mainProps,
  children,
  padding,
  widgets,
}) {
  const { baseUrl, appMode } = useContext(AppContext);

  return (
    <>
      <Nav>{widgets}</Nav>

      <div className={classnames(styles.root)}>
        {typeof title === "string" ? (
          <Title>{title}</Title>
        ) : title.type === Node ? (
          React.cloneElement(title, {
            edit: false,
            render: function render(state) {
              return (
                <Title>{state.type === "success" ? state.content : ""}</Title>
              );
            },
          })
        ) : (
          invalidTitle(title)
        )}

        {description == null
          ? null
          : description.type === Node
          ? React.cloneElement(description, {
              edit: false,
              render: function render(state) {
                const content =
                  state.type === "success" ? state.content.trim() : "";
                return content !== "" ? (
                  <Head>
                    <meta name="description" content={content} />
                    <meta property="og:description" content={content} />
                  </Head>
                ) : null;
              },
            })
          : invalidDescription(description)}

        <Head>
          {!appMode && canonical && (
            <link
              rel="canonical"
              href={
                canonical.startsWith("/") ? `${baseUrl}${canonical}` : canonical
              }
            />
          )}

          {noindex && <meta name="robots" content="noindex" />}
        </Head>

        <main
          {...mainProps}
          className={classnames(
            styles.main,
            {
              [styles.grey]: background === "grey",
              [styles.white]: background === "white",
              [styles.paddingTop]: padding === "top",
            },
            mainProps.className
          )}
        >
          {children}
        </main>

        {!appMode && <Footer />}
      </div>
    </>
  );
}

Title.propTypes = {
  children: PropTypes.string.isRequired,
};

function Title({ children }) {
  const title = children.trim();
  const suffix = ` | ${TITLE_BASE}`;
  const fullTitle =
    title === ""
      ? TITLE_BASE
      : title.endsWith(suffix)
      ? title
      : `${title}${suffix}`;
  return (
    <Head>
      <title>{fullTitle}</title>
      <meta property="og:title" content={fullTitle} />
    </Head>
  );
}

function invalidTitle(title) {
  throw new Error(
    `Invalid <Layout title>. Expected a string or a djedi <Node> but got: ${title}`
  );
}

function invalidDescription(description) {
  throw new Error(
    `Invalid <Layout description>. Expected a djedi <Node> but got: ${description}`
  );
}
